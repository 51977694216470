import Home from './home'

const Index = () => {

	return (
		<Home />
	)
}

export default Index
